const ContentReducer = (globalState, action) => {
  switch (action.type) {
    // case "GET_ALL_CONTENT":
    //   return {
    //     ...globalState,
    //     categories: action.payload,
    //   };
    case "GET_ALL_CONTENT":
      return {
        ...globalState,
        content: action.payload,
      };
    // case "GET_ALL_PRODUCTS_FEATURED":
    //   return {
    //     ...globalState,
    //     featuredProducts: action.payload,
    //   };

    default:
      return globalState;
  }
};

export default ContentReducer;
