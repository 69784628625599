import { CloudArrowUpIcon } from "@heroicons/react/20/solid";
import {
  ArrowPathIcon,
  FingerPrintIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";

const People = [
  {
    name: "Leslie Alexander",
    role: "Co-Founder / CEO",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Leslie Alexander",
    role: "Co-Founder / CEO",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Leslie Alexander",
    role: "Co-Founder / CEO",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Leslie Alexander",
    role: "Co-Founder / CEO",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Leslie Alexander",
    role: "Co-Founder / CEO",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    name: "Leslie Alexander",
    role: "Co-Founder / CEO",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
];
const Features = [
  {
    name: "BPS Library",
    description:
      "Tienes a tu disposición miles de ejercicios, juegos, videos entre otros recursos para profundizar tus conocimientos.",
    icon: CloudArrowUpIcon,
  },
  {
    name: "How to...",
    description:
      "¿Necesitar ayuda con la escritura de correos electrónicos profesionales o una preparación de presentaciones de negocios?",
    icon: LockClosedIcon,
  },
  {
    name: "Haz un Examen",
    description: "Encuentra tu examen asignado.",
    icon: ArrowPathIcon,
  },
  {
    name: "¿Tienes un proyecto?",
    description: "Estaremos contentos de trabajar juntos como equipo.",
    icon: FingerPrintIcon,
  },
];

const Tests = [
  {
    name: "toefl",
    bg: "bg-[#047D92]",
    src: "/images/toefl.png",
  },
  {
    name: "ielts",
    bg: "bg-[#E31836]",
    src: "/images/ielts.png",
  },
];

const Strengths = [
  {
    small: "Buscamos en todo momento un",
    name: "Enfoque personalizado",
    description:
      "En BPS nos enfocamos en las necesidades específicas de cada estudiante para marcar una gran diferencia. Esto significa que los programas de enseñanza deben ser adaptados al nivel de inglés, objetivos y preferencias de aprendizaje de cada estudiante.",
  },
  {
    small: "Nuestro principal activo es nuestro",
    name: "Equipo docente altamente capacitado:",
    description:
      "Con experiencia y capacitación especializada en la enseñanza de inglés. Esto es fundamental para una escuela de calidad. Un profesorado de alto nivel puede adaptarse a las necesidades y estilos de aprendizaje de los estudiantes para ofrecer un programa de enseñanza efectivo y motivador.",
  },
  {
    small: "Estamos siempre en busca de",
    name: "Metodologías innovadoras:",
    description:
      "La enseñanza del inglés puede ser más efectiva si se utilizan técnicas pedagógicas modernas, herramientas tecnológicas avanzadas y actividades prácticas y creativas que mantengan a los estudiantes involucrados y motivados.",
  },
  {
    small: "Ofrecemos a nuestros clientes",
    name: "Programas de enseñanza diversificados:",
    description:
      "Ofrecemos una amplia gama de programas de enseñanza con la misión de atraer a estudiantes con diferentes niveles de inglés y objetivos de aprendizaje. Esto incluye programas generales de inglés, programas de inglés para negocios, cursos de preparación para exámenes internacionales, entre otros.",
  },
  {
    small: "Listos para apoyar",
    name: "Flexibilidad de horarios:",
    description:
      "Creamos horarios flexibles atractivos para estudiantes que trabajan o tienen otras responsabilidades. Esto les permite adaptar su programa de estudios a sus horarios y necesidades específicas.",
  },
  {
    small: "Metas claras",
    name: "Certificaciones y reconocimientos:",
    description:
      "Preparamos a nuestros clcertificaciones y reconocimientos internacionales puede marcar una gran diferencia para los estudiantes que buscan mejorar sus habilidades en inglés con fines académicos o profesionales. Estas certificaciones y reconocimientos pueden aumentar la empleabilidad de los estudiantes y abrir oportunidades de estudio o trabajo en el extranjero.",
  },
];

const Stats = [
  {
    number: "+14,000",
    name: "Professionals",
  },
  {
    number: "142",
    name: "Companies",
  },
  {
    number: "4",
    name: "Countries",
  },
  {
    number: "1,203",
    name: "Degrees",
  },
];

const Navigation = [
  { name: "Nosotros", href: "/nosotros" },
  // { name: "Servicios", href: "/servicios" },
  { name: "Library", href: "/library/cta" },
  // { name: "Examenes", href: "/examenes" },
  // { name: "Contacto", href: "/contactanos" },
  { name: "Acceder", href: "/acceder" },
];

const NavigationLogin = [
  { name: "Nosotros", href: "/nosotros" },
  // { name: "Servicios", href: "/servicios" },
  { name: "Library", href: "/library/cta" },
  // { name: "Examenes", href: "/examenes" },
  // { name: "Contacto", href: "/contactanos" },
];

export {
  Tests,
  Features,
  People,
  Strengths,
  Stats,
  Navigation,
  NavigationLogin,
};
