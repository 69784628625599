/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import ContentContext from "../../Context/ContentContext/ContentContext";
import SelectCustom from "../../components/SelectCustom";
import StudentCardItems from "../../components/StudentCardItems";

const levelMock = [
  {
    id: "A1",
    name: "A1",
  },
  {
    id: "A2",
    name: "A2",
  },
  {
    id: "B1",
    name: "B1",
  },
  {
    id: "B2",
    name: "B2",
  },
  {
    id: "C1",
    name: "C1",
  },
  {
    id: "C2",
    name: "C2",
  },
];

export default function Library() {
  // const userContext = useContext(UserContext);
  // const { currentUser } = userContext;

  const contentContext = useContext(ContentContext);
  const { content, allContent } = contentContext;
  const [search, setSearch] = useState("");
  const [selectLevel, setSelectLevel] = useState(levelMock[1].id);

  const handleForm = (event) => {
    event.preventDefault();

    setSearch({
      ...search,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    allContent(search);
  };

  useEffect(() => {
    allContent({ search: selectLevel.id });
  }, [selectLevel]);

  return (
    <>
      {/* <Header variant="text-text" /> */}
      <div className="mx-auto max-w-7xl px-4 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="space-y-12">
          <div className=" pb-12 text-left">
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <form className="sm:col-span-3" onSubmit={handleSubmit}>
                <label
                  htmlFor="search"
                  className="block text-2xl leading-6 text-text font-bold"
                >
                  Búsqueda por palabra
                </label>
                <div className="mt-2">
                  <input
                    onChange={(e) => {
                      handleForm(e);
                    }}
                    id="search"
                    type="text"
                    name="search"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  />
                </div>
              </form>
              <div className="sm:col-span-3">
                <SelectCustom
                  list={levelMock}
                  selected={selectLevel}
                  setSelected={setSelectLevel}
                  label={"Nivel"}
                />
              </div>
              {/* <div className="sm:col-span-3">
            <SelectCustom
              list={levelMock}
              selected={selectLevel}
              setSelected={setSelectLevel}
              label={"Topic"}
            />
          </div>
          <div className="sm:col-span-3">
            <SelectCustom
              list={levelMock}
              selected={selectLevel}
              setSelected={setSelectLevel}
              label={"Tipo"}
            />
          </div>
          <div className="sm:col-span-3">
            <SelectCustom
              list={levelMock}
              selected={selectLevel}
              setSelected={setSelectLevel}
              label={"Estructura"}
            />
          </div> */}
            </div>
            <StudentCardItems content={content} />
          </div>
        </div>
      </div>
    </>
  );
}
