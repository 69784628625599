/* eslint-disable react-hooks/exhaustive-deps */
import { Menu, Transition } from "@headlessui/react";
import { EnvelopeIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useContext, useEffect, useState } from "react";
import UsersContext from "../../Context/UsersContext/UsersContext";
import SelectCustom from "../../components/SelectCustom";
import { classNames } from "../../utils/AuxFunctions";

const role = [
  {
    name: "Estudiante",
    id: "student",
  },
  {
    name: "Administrador",
    id: "admin",
  },
];

export default function Manager() {
  const userContext = useContext(UsersContext);
  const { getAllUsers, changeRole, users } = userContext;
  const [search, setSearch] = useState("");

  const [selectRole, setSelectRole] = useState(role[0]);

  const handleForm = (event) => {
    event.preventDefault();

    setSearch({
      ...search,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getAllUsers(search);
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    getAllUsers({ search: selectRole });
  }, [selectRole]);

  return (
    <div className="mx-auto max-w-7xl px-4 py-24 sm:px-6 sm:py-32 lg:px-8">
      <div className="space-y-12">
        <div className=" pb-12 text-left">
          <h2 className="text-3xl font-semibold leading-7 text-text">
            Usuarios
          </h2>
          <p className="mt-1 text-2xl leading-6 text-text">
            Administra estudiantes y personal.
          </p>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <form className="sm:col-span-3" onSubmit={handleSubmit}>
              <label
                htmlFor="url"
                className="block text-2xl leading-6 text-text font-bold"
              >
                Búsqueda por nombre
              </label>
              <div className="mt-2">
                <input
                  onChange={(e) => {
                    handleForm(e);
                  }}
                  type="text"
                  name="search"
                  placeholder="http://BPS/library/"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                />
              </div>
            </form>
            <div className="sm:col-span-3">
              <SelectCustom
                list={role}
                selected={selectRole}
                setSelected={setSelectRole}
                label={"Role"}
              />
            </div>
          </div>
          <ul className="space-y-3 mt-10">
            {users.map((user) => (
              <li
                key={user._id}
                className="col-span-1 flex rounded-md shadow-sm border-gray-200 border"
              >
                <div className="flex w-10 flex-shrink-0 items-center justify-center rounded-full bg-gray-200 p-2 m-5 ">
                  <div className="text-gray-400 text-sm font-medium w-full">
                    <EnvelopeIcon />
                  </div>
                </div>
                <div className="flex flex-1 items-center justify-between ">
                  <div className="flex-1  px-4 py-2 text-sm">
                    <div className="font-medium text-gray-900 hover:text-gray-600">
                      {user.name} {user.lastName}
                    </div>
                    <p className="text-gray-500">{user.email}</p>
                  </div>
                  <div className="flex-shrink-0 pr-2 overflow-visible">
                    {user.role !== "manager" ? (
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 capitalize  hover:bg-gray-50">
                            {user.role}
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <div
                                    onClick={() =>
                                      changeRole(user._id, {
                                        role: "student",
                                      })
                                    }
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                      "block px-4 py-2 text-sm cursor-pointer"
                                    )}
                                  >
                                    Estudiante
                                  </div>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <div
                                    onClick={() =>
                                      changeRole(user._id, {
                                        role: "admin",
                                      })
                                    }
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                      "block px-4 py-2 text-sm cursor-pointer"
                                    )}
                                  >
                                    Administrador
                                  </div>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    ) : (
                      <p className="text-gray-900 text-sm py-2 px-3 capitalize font-semibold">
                        {user.role}
                      </p>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
