import React from "react";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/isLoggedIn";

export default function CTA({ sethideCTA }) {
  const { isLoggedIn, currentUser } = useAuth();

  return (
    <>
      {/* <Header /> */}

      <div className="bg-white flex min-h-full flex-1 flex-col justify-center">
        <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-primary sm:text-6xl">
              What would you like to do Today?
            </h2>
            <div className="mt-10 flex items-center justify-center sm:gap-x-6 gap-3 flex-wrap">
              {isLoggedIn &&
                (currentUser?.role === "admin" ||
                  currentUser?.role === "manager") && (
                  <>
                    <Link
                      to="/library/add"
                      className="rounded-md  bg-primary px-20 py-5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                    >
                      +Add Content
                    </Link>
                    <Link
                      to="/library/manage"
                      className="rounded-md  bg-primary px-20 py-5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                    >
                      Edit Content
                    </Link>
                  </>
                )}
              <Link
                to="/library"
                className="rounded-md px-20  py-5 text-sm font-semibold border-solid border-2 border-primary text-primary shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
              >
                Go to Library
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
