import React from "react";
import { Features } from "../../../utils/data";

export default function LearnWithUs() {
  return (
    <>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-2xl font-semibold leading-7 text-text">
              Learn with us
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-primary sm:text-4xl">
              We're here to help
            </p>
            <p className="mt-6 text-lg leading-8 text-text">
              Acompañamos a nuestros alumnos a lo largo del su proceso de
              aprendizaje.
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {Features.map((feature, i) => (
                <div
                  key={feature.name}
                  className={`relative pt-16 p-5 rounded-xl ${
                    i === 0 ? "bg-primary" : "bg-[#F6F6F6]"
                  }`}
                >
                  <dt
                    className={`text-2xl sm:text-3xl font-semibold leading-7  ${
                      i === 0 ? "text-white" : "text-primary"
                    }`}
                  >
                    <div className="absolute  left-5 top-5 flex h-10 w-10 items-center justify-center rounded-lg bg-acent border-secondary border-solid border-2">
                      <feature.icon
                        className="h-6 w-6 text-primary"
                        aria-hidden="true"
                      />
                    </div>
                    {feature.name}
                  </dt>
                  <dd
                    className={`mt-2 text-2x1 leading-7  ${
                      i === 0 ? "text-white" : "text-text"
                    }`}
                  >
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}
