import React, { useReducer } from "react";
import { toast } from "react-toastify";
import axiosClient from "../../config/axiosClient";

import ContentContext from "./ContentContext";
import ContentReducer from "./ContentReducer";

const ContentProvider = (props) => {
  const initialState = {
    content: [],
  };

  const [globalState, dispatch] = useReducer(ContentReducer, initialState);

  const allContent = async (data = {}) => {
    try {
      const res = await axiosClient.post("/library/readall", data);
      dispatch({
        type: "GET_ALL_CONTENT",
        payload: res.data.activities,
      });
    } catch (error) {
      toast.error("Ha ocurrido un error, intente nuevamente", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const updateContent = async (data) => {
    try {
      await axiosClient.put(`/library/edit/${data._id}`, data);
      await allContent();
      toast.success("Contenido actualizado con exito", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      toast.error("Ha ocurrido un error, intente nuevamente", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const createContent = async (data) => {
    try {
      const res = await axiosClient.post(`/library/create`, data);
      return res.data;
    } catch (error) {
      toast.error("Ha ocurrido un error, intente nuevamente", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const removeContent = async (_id) => {
    try {
      await axiosClient.delete(`/library/delete/${_id}`);
      toast.success("Contenido eliminado con exito", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      allContent();
    } catch (error) {
      toast.error("Ha ocurrido un error, intente nuevamente", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const uploadCSV = async (data) => {
    try {
      const res = await axiosClient.post(`/uploadfile`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      toast.error("Ha ocurrido un error, intente nuevamente", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <ContentContext.Provider
      value={{
        content: globalState.content,
        createContent,
        allContent,
        updateContent,
        removeContent,
        uploadCSV,
      }}
    >
      {props.children}
    </ContentContext.Provider>
  );
};

export default ContentProvider;
