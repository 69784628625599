import Footer from "../../components/Footer";
import HeroBanner from "../../components/HeroBanner";
import StatsComponent from "../../components/common/StatsComponent";
import { Strengths } from "../../utils/data";

const Us = () => {
  return (
    <>
      {/* <Header /> */}
      <HeroBanner
        title="Somos BPS"
        description="Nos distingue un enfoque personalizado, un equipo docente altamente capacitado, metodologías innovadoras, programas de enseñanza diversificados, flexibilidad de horarios y certificaciones y reconocimientos internacionales. "
        image="/images/nosotros.png"
      />
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <p className="mt-2 text-3xl font-bold tracking-tight text-primary sm:text-6xl">
              Nuestras fortalezas
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {Strengths.map((strength, i) => (
                <div
                  key={strength.name}
                  className="relative pt-16 p-5 text-left"
                >
                  <small className="text-primary">{strength.small}</small>
                  <dt className="text-2xl sm:text-3xl font-semibold leading-7 ">
                    {strength.name}
                  </dt>
                  <dd className="mt-2 text-2x1 leading-7">
                    {strength.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <StatsComponent />
      <Footer />
    </>
  );
};

export default Us;
