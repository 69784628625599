import { Menu, Transition } from "@headlessui/react";
import {
  DocumentMagnifyingGlassIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import DeleteContent from "../../pages/Library/DeleteContent";
import { classNames } from "../../utils/AuxFunctions";

export default function AdminCardItems({ content }) {
  const [open, setOpen] = useState(false);
  const [id, setid] = useState();

  return (
    <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
      <DeleteContent open={open} setOpen={setOpen} id={id} />
      {content.map((activity) => (
        <li key={activity._id}>
          <div className="col-span-1 flex rounded-md shadow-sm overflow-visible rounded-r-md border-solid border-2 border-gray-200 bg-white">
            <div className="flex ml-4 items-center my-4 p-1 justify-center text-sm font-medium border-solid border-2 rounded-full">
              <DocumentMagnifyingGlassIcon className="h-6 text-emerald-800" />
            </div>
            <div className="flex flex-1 items-center justify-between overflow-visible">
              <div className="flex-1  px-4 py-2 text-sm">
                <Link
                  to={`/library/${activity._id}`}
                  state={{ activity }}
                  className="font-medium text-gray-900 hover:text-gray-600"
                >
                  {activity.title}
                </Link>
                <p className="text-gray-500">
                  #{activity.structure} #{activity.type} #{activity.level}
                </p>
              </div>
              <div className="flex-shrink-0 pr-2 overflow-visible">
                <Menu
                  as="div"
                  className="inline-flex h-10 w-4 border-solid border-2 mt-2 items-center justify-center rounded-sm bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 relative overflow-visible"
                >
                  <Menu.Button className="flex items-center rounded-sm  text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-primary  focus:ring-offset-gray-100">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon
                      className="h-10 w-4"
                      aria-hidden="true"
                    />
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-3 z-10 mt-2 top-6 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={`/library/manage/edit/${activity._id}`}
                              state={{ activity }}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Editar
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => {
                                setOpen(!open);
                                setid(activity._id);
                              }}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm w-full"
                              )}
                            >
                              Eliminar
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
