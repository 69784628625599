import { Field, Form, Formik } from "formik";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ContentContext from "../../Context/ContentContext/ContentContext";

const SignupSchema = Yup.object().shape({
  titulo: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  descripcion: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  nivel: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  topic: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  tipo: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  estructura: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  url: Yup.string().min(2, "Too Short!").required("Required"),
});

export default function EditContent() {
  const location = useLocation();
  let navigate = useNavigate();

  const { activity } = location.state;

  const contentContext = useContext(ContentContext);
  const { updateContent } = contentContext;

  return (
    <>
      {/* <Header /> */}
      <>
        <div className="mx-auto max-w-7xl px-4 pt-24 sm:px-6 sm:pt-32 lg:px-8">
          <img src="/images/bg.png" className="w-full" alt="" />
        </div>
        <Formik
          initialValues={{
            titulo: activity.title,
            descripcion: activity.description,
            nivel: activity.level,
            topic: activity.topic,
            tipo: activity.type,
            estructura: activity.structure,
            url: activity.url,
          }}
          validationSchema={SignupSchema}
          onSubmit={async (values) => {
            await updateContent({
              title: values.titulo,
              description: values.descripcion,
              level: values.nivel,
              topic: values.topic,
              type: values.tipo,
              structure: values.estructura,
              url: values.url,
              _id: activity._id,
            });
            navigate("/library/manage");
          }}
        >
          {({ errors, touched, initialValues }) => (
            <Form className="mx-auto max-w-7xl px-4 pt-8 pb-24 sm:px-6 sm:pb-32 lg:px-8">
              <div className="space-y-12">
                <div className=" pb-12 text-left">
                  <h2 className="text-2xl font-semibold leading-7 text-text">
                    {initialValues.titulo}
                  </h2>
                  <p className="mt-1 text-base leading-6 text-text">
                    {initialValues.descripcion}
                  </p>

                  <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="titulo"
                        className="block text-2xl leading-6 text-text font-bold"
                      >
                        Titulo
                      </label>
                      <div className="mt-2">
                        <Field
                          name="titulo"
                          type="text"
                          placeholder="Ejercicio 1"
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 ${
                            errors.titulo && touched.titulo && "ring-primary"
                          }`}
                        />
                        {errors.titulo && touched.titulo ? (
                          <small className="ml-1 text-primary">
                            {errors.titulo}
                          </small>
                        ) : null}
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="descripcion"
                        className="block text-2xl leading-6 text-text font-bold"
                      >
                        Descripcion
                      </label>
                      <div className="mt-2">
                        <Field
                          type="text"
                          name="descripcion"
                          placeholder="Ejercicio sobre..."
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 ${
                            errors.descripcion &&
                            touched.descripcion &&
                            "ring-primary"
                          }`}
                        />
                        {errors.descripcion && touched.descripcion ? (
                          <small className="ml-1 text-primary">
                            {errors.descripcion}
                          </small>
                        ) : null}
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="nivel"
                        className="block text-2xl leading-6 text-text font-bold"
                      >
                        Nivel
                      </label>
                      <div className="mt-2">
                        <Field
                          name="nivel"
                          type="text"
                          placeholder="C2"
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 ${
                            errors.nivel && touched.nivel && "ring-primary"
                          }`}
                        />
                        {errors.nivel && touched.nivel ? (
                          <small className="ml-1 text-primary">
                            {errors.nivel}
                          </small>
                        ) : null}
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="topic"
                        className="block text-2xl leading-6 text-text font-bold"
                      >
                        Topic
                      </label>
                      <div className="mt-2">
                        <Field
                          type="text"
                          name="topic"
                          placeholder="Verb To Be"
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 ${
                            errors.topic && touched.topic && "ring-primary"
                          }`}
                        />
                        {errors.topic && touched.topic ? (
                          <small className="ml-1 text-primary">
                            {errors.topic}
                          </small>
                        ) : null}
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="tipo"
                        className="block text-2xl leading-6 text-text font-bold"
                      >
                        Tipo
                      </label>
                      <div className="mt-2">
                        <Field
                          type="text"
                          name="tipo"
                          placeholder="Video"
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 ${
                            errors.tipo && touched.tipo && "ring-primary"
                          }`}
                        />
                        {errors.tipo && touched.tipo ? (
                          <small className="ml-1 text-primary">
                            {errors.tipo}
                          </small>
                        ) : null}
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="estructura"
                        className="block text-2xl leading-6 text-text font-bold"
                      >
                        Estructura
                      </label>
                      <div className="mt-2">
                        <Field
                          type="text"
                          name="estructura"
                          placeholder="Verbos"
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 ${
                            errors.estructura &&
                            touched.estructura &&
                            "ring-primary"
                          }`}
                        />
                        {errors.estructura && touched.estructura ? (
                          <small className="ml-1 text-primary">
                            {errors.estructura}
                          </small>
                        ) : null}
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="url"
                        className="block text-2xl leading-6 text-text font-bold"
                      >
                        URL
                      </label>
                      <div className="mt-2">
                        <Field
                          type="text"
                          name="url"
                          placeholder="http://BPS/library/"
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 ${
                            errors.url && touched.url && "ring-primary"
                          }`}
                        />
                        {errors.url && touched.url ? (
                          <small className="ml-1 text-primary">
                            {errors.url}
                          </small>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-6 flex items-center justify-center gap-x-6">
                <button
                  type="submit"
                  className="rounded-md bg-primary px-14 py-2 text-base font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
                >
                  Guardar
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </>
    </>
  );
}
