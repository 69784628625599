import React from "react";
import { Link } from "react-router-dom";
export default function StudentCardItems({ content }) {
  return (
    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-16">
      {content.map((activity) => (
        <li
          key={activity._id}
          className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow-lg"
        >
          <Link
            to={`/library/${activity._id}`}
            state={{ activity }}
            className="flex w-full items-center justify-between space-x-6 p-6"
          >
            <div className="flex-1">
              <div className="flex items-center">
                <h3 className="line-clamp-2 text-2xl font-medium text-text">
                  {activity.title}
                </h3>
              </div>
              <p className="mt-1 truncate text-base text-text">
                Descripción: {activity.description}
              </p>
              <small className="text-gray-500">
                #{activity.structure} - #{activity.type} - #{activity.level}
              </small>
            </div>
            <img
              className=" w-16 flex-shrink-0  bg-gray-300"
              src={"https://m.media-amazon.com/images/I/51s8-zcdSIL.jpg"}
              alt=""
            />
          </Link>
        </li>
      ))}
    </ul>
  );
}
