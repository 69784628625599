import React from "react";
import ContentCardItem from "../../components/ContentCard";

export default function ContentDetails() {
  return (
    <>
      <div className="mx-auto max-w-7xl px-4 pt-24 sm:px-6 sm:pt-32 lg:px-8 flex items-center justify-center">
        <img
          src={"https://m.media-amazon.com/images/I/51s8-zcdSIL.jpg"}
          className="w-52"
          alt=""
        />
      </div>
      {/* <Header /> */}
      <ContentCardItem />
    </>
  );
}
