/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import ContentContext from "../../Context/ContentContext/ContentContext";
import AdminCardItems from "../../components/AdminCardItems";

export default function Admin() {
  const contentContext = useContext(ContentContext);
  const { content, allContent } = contentContext;
  const [search, setSearch] = useState("");

  const handleForm = (event) => {
    event.preventDefault();

    setSearch({
      ...search,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    allContent(search);
  };

  useEffect(() => {
    allContent();
  }, []);

  return (
    <>
      {/* <Header /> */}
      <div className="mx-auto max-w-7xl px-4 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="space-y-12">
          <div className=" pb-12 text-left">
            <h2 className="text-3xl font-semibold leading-7 text-text">
              Toda la librería
            </h2>
            <p className="mt-1 text-2xl leading-6 text-text">
              Administra tu librería
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <form className="sm:col-span-6" onSubmit={handleSubmit}>
                <label
                  htmlFor="url"
                  className="block text-2xl leading-6 text-text font-bold"
                >
                  Búsqueda por palabra
                </label>
                <div className="mt-2">
                  <input
                    onChange={(e) => {
                      handleForm(e);
                    }}
                    type="text"
                    name="search"
                    placeholder="http://BPS/library/"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  />
                </div>
              </form>
              {/* <div className="sm:col-span-3">
                <SelectCustom
                  list={levelMock}
                  selected={selectLevel}
                  setSelected={setSelectLevel}
                  label={"Nivel"}
                />
              </div>
              <div className="sm:col-span-3">
                <SelectCustom
                  list={levelMock}
                  selected={selectLevel}
                  setSelected={setSelectLevel}
                  label={"Topic"}
                />
              </div>
              <div className="sm:col-span-3">
                <SelectCustom
                  list={levelMock}
                  selected={selectLevel}
                  setSelected={setSelectLevel}
                  label={"Tipo"}
                />
              </div>
              <div className="sm:col-span-3">
                <SelectCustom
                  list={levelMock}
                  selected={selectLevel}
                  setSelected={setSelectLevel}
                  label={"Estructura"}
                />
              </div> */}
            </div>
          </div>
        </div>

        <AdminCardItems content={content} />
      </div>
    </>
  );
}
