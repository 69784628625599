import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function ContentCardItem() {
  const location = useLocation();
  const { activity } = location.state;
  return (
    <div className="mx-auto max-w-7xl px-4 pt-24 sm:px-6 sm:pt-32 lg:px-8">
      <div className="space-y-12">
        <div className=" pb-12 text-left">
          <h2 className="text-2xl font-semibold leading-7 text-text">
            {activity.title}
          </h2>
          <p className="mt-1 text-base leading-6 text-text">
            {activity.description}
          </p>
          <small className="text-gray-500">
            #{activity.structure} - #{activity.type} - #{activity.level} - #
            {activity.topic}
          </small>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <Link
          to={activity.url}
          target="_blank"
          rel="noreferrer"
          className="rounded-md bg-primary px-14 py-2 text-base font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
        >
          Go
        </Link>
      </div>
    </div>
  );
}
