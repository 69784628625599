import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";

import { Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import useAuth from "./hooks/isLoggedIn";
import LandingStudent from "./pages/LandingStudent";
import Library from "./pages/Library";
import AddContent from "./pages/Library/AddContent";
import Admin from "./pages/Library/Admin";
import CTA from "./pages/Library/CTA";
import ContentDetails from "./pages/Library/ContentDetails";
import EditContent from "./pages/Library/EditContent";
import Login from "./pages/Login";
import Manager from "./pages/Manager";
import Register from "./pages/Register";
import Us from "./pages/Us";

function App() {
  const { isLoggedIn, currentUser } = useAuth();
  const location = useLocation();
  const path = location.pathname;
  return (
    <div className="App h-full">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header
        variant={
          path === "/acceder" || path === "/registrate"
            ? "text-white"
            : "text-primary"
        }
      />

      <Routes>
        <Route exact path="/" element={<LandingStudent />} />
        <Route exact path={`/nosotros`} element={<Us />} />

        {/* AUTH ROUTES */}

        <Route
          exact
          path={`/acceder`}
          element={isLoggedIn ? <Navigate to="/" /> : <Login />}
        />
        <Route
          exact
          path={`/registrate`}
          element={isLoggedIn ? <Navigate to="/" /> : <Register />}
        />

        {/* PRIVATED ROUTES */}

        <Route
          exact
          path={`/library`}
          element={isLoggedIn ? <Library /> : <Navigate to="/acceder" />}
        />
        <Route
          exact
          path={`/library/:contentId`}
          element={isLoggedIn ? <ContentDetails /> : <Navigate to="/acceder" />}
        />

        {/* ADMIN ROUTES */}

        <Route
          exact
          path={`/library/cta`}
          element={isLoggedIn ? <CTA /> : <Navigate to="/acceder" />}
        />

        <Route
          exact
          path={`/library/manage`}
          element={
            currentUser?.role === "admin" || currentUser?.role === "manager" ? (
              <Admin />
            ) : (
              <Navigate to="/acceder" />
            )
          }
        />
        <Route
          exact
          path={`/library/manage/edit/:contentId`}
          element={
            currentUser?.role === "admin" || currentUser?.role === "manager" ? (
              <EditContent />
            ) : (
              <Navigate to="/acceder" />
            )
          }
        />
        <Route
          exact
          path={`/library/add`}
          element={
            currentUser?.role === "admin" || currentUser?.role === "manager" ? (
              <AddContent />
            ) : (
              <Navigate to="/acceder" />
            )
          }
        />

        {/* MANAGER ROUTES */}

        <Route
          exact
          path={`/manager/users`}
          element={
            currentUser?.role === "manager" ? (
              <Manager />
            ) : (
              <Navigate to="/acceder" />
            )
          }
        />
      </Routes>
    </div>
  );
}

export default App;
