import Footer from "../../components/Footer";
// import Header from "../../components/Header";
import HeroBanner from "../../components/HeroBanner";
import LearnWithUs from "../../components/common/LearnWithus";
import StatsComponent from "../../components/common/StatsComponent";
import useAuth from "../../hooks/isLoggedIn";
import { Tests } from "../../utils/data";

export default function LandingStudent() {
  const { isLoggedIn, currentUser } = useAuth();

  return (
    <>
      {/* <Header /> */}
      {isLoggedIn && currentUser?.role === "admin" ? (
        <HeroBanner
          title="Are you ready?"
          description="Creamos una experiencia educativa de alta calidad que marca la
                diferencia en la vida de los estudiantes."
          image="/images/landing-background.png"
          link="/library/cta"
        />
      ) : (
        <HeroBanner
          title="Are you ready?"
          description="En BPS estamos preocupados por una formación integral en nuestros alumnos que les den todas las herramientas y habilidades para enfrentar el día a día."
          image="/images/landing-background.png"
          link="/library/cta"
        />
      )}

      <LearnWithUs />
      <div className="bg-acent py-24 sm:pb-32 hidden sm:flex">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-2xl font-semibold leading-7 text-text">
              We got the
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-primary sm:text-4xl">
              Recipe for Success
            </p>
            <p className="mt-6 text-lg leading-8 text-text">
              Contamos con programas de preparación para exámenes
              internacionales reconocidos como:
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {Tests.map((feature) => (
                <div
                  key={feature.name}
                  className={`px-18 py-5 flex justify-center ${feature.bg} rounded-xl`}
                >
                  <img src={feature.src} alt="" />
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <StatsComponent />
      <Footer />
    </>
  );
}
