import React, { useContext } from "react";
import ContentContext from "../../Context/ContentContext/ContentContext";

export default function DragDrop({
  setShowSuccessScreen,
  setActivityLevelUpload,
  setamount,
}) {
  const contentContext = useContext(ContentContext);
  const { uploadCSV } = contentContext;
  // drag state
  const [dragActive, setDragActive] = React.useState(false);
  // ref
  const inputRef = React.useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files[0]);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files[0]);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  const handleFiles = async (file) => {
    const formData = new FormData();
    formData.append("uploadfile", file);
    const res = await uploadCSV(formData);
    if (res) {
      setShowSuccessScreen(true);
      setActivityLevelUpload(res.activites[0].level);
      setamount(res.activites.length);
    }
  };
  return (
    <form
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
      className="mx-auto max-w-7xl px-4 py-24 sm:px-6 "
    >
      <input
        ref={inputRef}
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        id="input-file-upload"
        multiple={true}
        onChange={handleChange}
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={dragActive ? "drag-active" : ""}
      >
        <div>
          <p className="text-2xl font-semibold leading-7 text-text">
            Arrastra o toca aquí
          </p>
          <button
            className="upload-button mt-1 text-base leading-6 text-text"
            onClick={onButtonClick}
          >
            Sube todo tu contenido fácilmente seleccionando o arrastrando aquí
            el documento.
          </button>
        </div>
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
}
