/* eslint-disable react-hooks/exhaustive-deps */
import { Menu, Transition } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/isLoggedIn";

import UsersContext from "../../Context/UsersContext/UsersContext";
import { classNames } from "../../utils/AuxFunctions";
import { Navigation, NavigationLogin } from "../../utils/data";

export default function Header(props) {
  const [currentNavitation, setcurrentNavitation] = useState(Navigation);
  const { isLoggedIn, currentUser } = useAuth();

  const userContext = useContext(UsersContext);
  const { logOut } = userContext;

  const { variant = "text-primary" } = props;

  useEffect(() => {
    if (isLoggedIn && currentUser) setcurrentNavitation(NavigationLogin);
    else setcurrentNavitation(Navigation);
  }, [isLoggedIn, currentUser]);

  return (
    <header className="absolute inset-x-0 top-0 z-50">
      <nav
        className="flex items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">BPS</span>
            <img className="h-16 w-auto" src="/images/logo.svg" alt="" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button
                className={`flex items-center   border-solid border-2 rounded-md border-white p-2 focus:outline-none focus:ring-1 focus:ring-primary focus:ring-offset-2 ${variant}`}
              >
                <span className="sr-only">Open options</span>
                <Bars3Icon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {currentNavitation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            active ? "bg-gray-100 underline" : "",
                            "block px-2 py-4 text-sm text-center font-bold text-primary"
                          )}
                        >
                          {item.name}
                        </Link>
                      )}
                    </Menu.Item>
                  ))}
                  {currentUser?.role === "manager" && (
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="/manager/users"
                          className={classNames(
                            active ? "bg-gray-100 underline" : "",
                            "block px-2 py-4 text-sm text-center font-bold text-primary"
                          )}
                        >
                          Usuarios
                        </Link>
                      )}
                    </Menu.Item>
                  )}
                  {isLoggedIn && (
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => logOut()}
                          className={classNames(
                            active ? "bg-gray-100 underline" : "",
                            "block px-2 py-4 text-sm text-center font-bold text-primary"
                          )}
                        >
                          Salir
                        </div>
                      )}
                    </Menu.Item>
                  )}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>

        <div className="hidden lg:flex lg:gap-x-12">
          {currentNavitation.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className={`text-base font-semibold leading-6 ${variant}`}
            >
              {item.name}
            </Link>
          ))}
          {currentUser?.role === "manager" && (
            <Link
              to="/manager/users"
              className={`text-base font-semibold leading-6 ${variant}`}
            >
              Usuarios
            </Link>
          )}
          {isLoggedIn && (
            <div
              onClick={() => logOut()}
              className={`text-base font-semibold leading-6 cursor-pointer underline ${variant}`}
            >
              Salir
            </div>
          )}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end"></div>
      </nav>
    </header>
  );
}
