import { AtSymbolIcon, PhoneIcon } from "@heroicons/react/20/solid";
import React from "react";
import { Stats } from "../../../utils/data";

export default function StatsComponent() {
  return (
    <>
      <div className="bg-text py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <p className="mt-2 text-3xl font-bold tracking-tight text-primary sm:text-6xl">
              We've worked with...
            </p>
          </div>
          <div className="mx-auto mt-10 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-2 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {Stats.map((stat, i) => (
                <div key={stat.name} className="relative pt-16 p-5">
                  <dt className="text-2xl sm:text-3xl font-semibold leading-7 text-white">
                    {stat.number}
                  </dt>
                  <dd className="mt-2 text-2xl sm:text-3xl leading-7 text-white">
                    {stat.name}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <div className="relative isolate overflow-hidden bg-text px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="lg:max-w-lg">
                <h1 className="mt-2 text-3xl text-left font-bold tracking-tight text-white sm:text-4xl">
                  They've Already Trust on Us
                </h1>
                <p className="text-left mt-6 text-xl leading-8 text-white">
                  Entre nuestra lista de clientes nacionales e internacionales,
                  contamos con empresas de diversas áreas e industrias, cada una
                  con la finalidad de mejorar las habilidades de sus
                  colaboradores y expandir su presencia en el mercado global.
                </p>
              </div>
            </div>
            <div className="lg:pr-4">
              <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                <ul className="mt-8 space-y-8 text-gray-600">
                  <li className="flex gap-x-3">
                    <AtSymbolIcon
                      className="mt-1 h-5 w-5 flex-none text-primary"
                      aria-hidden="true"
                    />
                    <span className="text-white">
                      bps.enterprise@hotmail.com
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <PhoneIcon
                      className="mt-1 h-5 w-5 flex-none text-primary"
                      aria-hidden="true"
                    />
                    <span className="text-white">+52 1 55 6800 2264</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
